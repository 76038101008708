import { Observable } from 'rxjs';
import { Product, Stock } from '../../../core/models/product';
import { DistributorStock, CatalogProduct, MeasurementUnitCoef } from '../../../core/models/catalog-products.model';

export abstract class CartProductsFacade {
  abstract increment(product: CatalogProduct): void;
  abstract decrement(product: CatalogProduct): void;
  abstract changeAmount({ product, amount }): void;
  abstract toggleFavorite(product): void;
  abstract getIsFavorite(product): Observable<boolean>;
  abstract getQuantity(product: CatalogProduct): Observable<any>;
  abstract getPossibleToDecrement(product: CatalogProduct): Observable<any>;
  abstract getPossibleToIncrement(product: CatalogProduct): Observable<any>;
  abstract getMaxQuantity(product: CatalogProduct): Observable<any>;
  abstract getStock(product: CatalogProduct): Observable<any>;
  abstract getStockMap(): Observable<any>;
  abstract setStock(productId: number, stock: Stock): void;
  abstract setProduct(products: CatalogProduct[]): void;
  abstract getProductStockMap(): Observable<any>;
  abstract getDistributorByProductId(productId: number): Observable<DistributorStock>;
  abstract setDistributor(productId: number, distributor: DistributorStock): void;
  abstract getMeasurementUnitsCoefByProductId(productId: number): Observable<MeasurementUnitCoef>;
  abstract setMeasurementUnitsCoef(productId: number, coef: MeasurementUnitCoef): void;
}
