import { Subject } from 'rxjs';
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { CatalogProduct, DistributorStock, MeasurementUnitCoef } from '../../../../../../core/models/catalog-products.model';

@Component({
  selector: 'app-catalog-unit-modal',
  templateUrl: './catalog-unit-modal.component.html',
  styleUrls: ['./catalog-unit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogUnitModalComponent implements OnInit {
  product: CatalogProduct;
  currentDistributor: DistributorStock;
  onClose: Subject<MeasurementUnitCoef> = new Subject();
  currentMeasurementUnit: MeasurementUnitCoef;
  selectedMeasurementUnit: MeasurementUnitCoef;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  setCurrentDistributor(distributor: DistributorStock): void {
    this.currentDistributor = distributor;
    this.cdr.markForCheck();
  }

  setCurrentMeasurementUnit(unit: MeasurementUnitCoef): void {
    this.currentMeasurementUnit = unit;
    this.selectedMeasurementUnit = unit;
    this.cdr.markForCheck();
  }

  onChoose(unit: MeasurementUnitCoef): void {
    this.onClose.next(unit);
  }

  selectCurrentProduct(product: CatalogProduct): void {
    this.product = product;
    this.cdr.markForCheck();
  }

  close(): void {
    this.onClose.next(null);
  }

  get measurementUnits(): MeasurementUnitCoef[] {
    return this.currentDistributor ? this.currentDistributor?.stockResponse?.measurementUnitsCoefResponses : [];
  }
}
