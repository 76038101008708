import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Subject } from 'rxjs';
import { CatalogProduct, DistributorStock } from '../../../../../../core/models/catalog-products.model';

@Component({
  selector: 'app-catalog-distributor-modal',
  templateUrl: './catalog-distributor-modal.component.html',
  styleUrls: ['./catalog-distributor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogDistributorModalComponent {
  product: CatalogProduct;
  currentDistributor: DistributorStock;
  selectedDistributor: DistributorStock;
  onClose: Subject<DistributorStock> = new Subject();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  setCurrentDistributor(distributor: DistributorStock): void {
    this.currentDistributor = distributor;
    this.selectedDistributor = distributor;
    this.cdr.markForCheck();
  }

  onChoose(distributor: DistributorStock): void {
    this.onClose.next(distributor);
  }

  setCurrentProduct(product: CatalogProduct): void {
    this.product = product;
    this.cdr.markForCheck();
  }

  close(): void {
    this.onClose.next(null);
  }

  getListDistributors(): DistributorStock[] {
    if (!this.currentDistributor) {
      return [];
    }
    return this.product.catalogDistributors.filter((distributor) => distributor.id === this.currentDistributor?.id);
  }
}
