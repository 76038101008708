import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { taskSelectors } from '@Mesh/store/task/task.selectors';
import { ModerationTaskLoadPageRequest, TaskLoadPageRequest, TaskLoadRequest, TaskProgressLoad } from '@Mesh/store/task/task.actions';
import { Task } from '@Mesh/core/models/task';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tasks-widget',
  templateUrl: './tasks-widget.component.html',
  styleUrls: ['./tasks-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksWidgetComponent {
  activeTasks$ = this.store.select(taskSelectors.getActiveTasksTasks);
  moderationTasks$ = this.store.select(taskSelectors.getModerationTasks);
  totalActiveTasks$ = this.store.select(taskSelectors.getActiveTotalCount);
  totalModerationTasks$ = this.store.select(taskSelectors.getModerationTotalCount);
  totalTasksPages$ = this.store.select(taskSelectors.getTotalPages);

  constructor(private store: Store<AppState>, private router: Router, private route: ActivatedRoute) {}

  loadTasksPages(page: number) {
    this.store.dispatch(new TaskLoadPageRequest({ page, itemsPerPage: 10, onlyActual: true, notOverdue: true }));
  }

  loadAllTasks() {
    this.store.dispatch(new TaskLoadRequest({ pageNumber: 0, pageSize: 9999, completed: false, onlyActual: true, notOverdue: true }));
  }

  loadModerationTasksPages(page: number) {
    this.store.dispatch(
      new ModerationTaskLoadPageRequest({ page: page, itemsPerPage: 10, completed: false, onlyActual: true, notOverdue: true })
    );
  }

  navToTaskDetails(task: Task) {
    this.store.dispatch(new TaskProgressLoad({ taskOutletClientId: task.id }));
    const queryParams = {
      action: 'chat',
      action_type: 'task-comments',
      action_id: task.id,
      task_id: task.taskId,
      address_sap_id: task.addressId,
      client_sap_id: task.clientId,
      sent_by: null,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  navToAllTasks() {
    this.router.navigate(['/retail-tasks']);
  }
}
