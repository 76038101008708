import {
  OutletAwaitingSumLoadError,
  OutletAwaitingSumLoadRequest,
  OutletAwaitingSumLoadSuccess,
  ResetBonusValue,
  SelectedUserBonusBalanceLoadError,
  SelectedUserBonusBalanceLoad,
  SelectedUserBonusBalanceLoadSuccess,
  SetActiveBonus,
} from './bonus.actions';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { AuthenticationService } from '@Mesh/core/services';
import { BonusService } from '@Mesh/core/services/bonus.service';
import {
  BonusActionsTypes,
  ClientBonusLoadError,
  ClientBonusLoadRequest,
  ClientBonusLoadSuccess,
  ClientBonusPredictLoadError,
  ClientBonusPredictLoadRequest,
  ClientBonusPredictLoadSuccess,
  ClientBonusPredictTotalAmountLoadSuccess,
  ClientsBonusByPeriodLoadError,
  ClientsBonusByPeriodLoadRequest,
  ClientsBonusByPeriodLoadSuccess,
  OutletBonusesLoadError,
  OutletBonusesLoadRequest,
  OutletBonusesLoadSuccess,
  OutletBonusesPredictLoadError,
  OutletBonusesPredictLoadRequest,
  OutletBonusesPredictLoadSuccess,
  OutletBonusPredictLoadSuccess,
  OutletBonusPredictLoadRequest,
  OutletBonusPredictLoadError,
  LoadPrediction,
} from '@Mesh/store/bonus/bonus.actions';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import { IProductParamPlan, ProductTableService } from '@Mesh/core/services/product-table.service';
import { OutletActionsTypes, OutletSetActive, OutletSetActiveId } from '../outlet/outlet.actions';

@Injectable()
export class BonusEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private bonusService: BonusService,
    private authService: AuthenticationService,
    private productTableService: ProductTableService
  ) {}

  @Effect()
  load: Observable<Action> = this.actions$.pipe(
    ofType<ClientBonusLoadRequest>(BonusActionsTypes.ClientBonusLoadRequest),
    switchMap(() =>
      of(this.authService.currentUser).pipe(
        filter((user) => !!user),
        mergeMap((user) => this.bonusService.getClientBonus(user.client.id)),
        map((res) => new ClientBonusLoadSuccess({ data: res }))
      )
    ),
    catchError((error) => of(new ClientBonusLoadError(error)))
  );

  @Effect()
  loadPredictBonus: Observable<Action> = this.actions$.pipe(
    ofType<ClientBonusPredictLoadRequest>(BonusActionsTypes.ClientBonusPredictLoadRequest),
    switchMap(() =>
      of(this.authService.currentUser).pipe(
        filter((user) => !!user),
        mergeMap((user) =>
          forkJoin([
            this.bonusService.getClientBonusPredictFromTask(user.client.id),
            this.bonusService.getClientBonusPredictFromSalePlan(user.client.id),
          ])
        ),
        mergeMap(([clientBonusPredict, clientBonusPredictFromSalePlan]) => {
          const clientBonusPredictTotalAmount = clientBonusPredict?.bonus + clientBonusPredictFromSalePlan?.bonus;
          return [
            new ClientBonusPredictLoadSuccess({ data: clientBonusPredict }),
            new ClientBonusPredictTotalAmountLoadSuccess({
              total: clientBonusPredictTotalAmount,
            }),
          ];
        })
      )
    ),
    catchError((error) => of(new ClientBonusPredictLoadError(error)))
  );

  @Effect()
  loadAwaitingSum: Observable<Action> = this.actions$.pipe(
    ofType<OutletAwaitingSumLoadRequest>(BonusActionsTypes.OutletAwaitingSumLoadRequest),
    switchMap((action) =>
      this.store$.select(outletSelectors.getActive).pipe(
        filter((outlet) => !!outlet),
        mergeMap((outlet) =>
          forkJoin([
            this.bonusService.getOutletBonusesPredict({
              ...action.payload,
              addressId: outlet.id,
            }),
            this.bonusService.getOutletBonusPredict(outlet.id),
          ])
        ),
        map(([sale, task]) => {
          let bonusSum = 0;
          sale?.forEach((data) => (bonusSum += data.currentBonus));
          return new OutletAwaitingSumLoadSuccess({ data: bonusSum + task.bonus });
        })
      )
    ),
    catchError((error) => of(new OutletAwaitingSumLoadError(error)))
  );

  @Effect()
  loadClientsBonusByPeriod: Observable<Action> = this.actions$.pipe(
    ofType<ClientsBonusByPeriodLoadRequest>(BonusActionsTypes.ClientsBonusByPeriodLoadRequest),
    switchMap((action) => this.bonusService.getClientsBonusByPeriod(action.payload.startAt, action.payload.startAt)),
    map((res) => new ClientsBonusByPeriodLoadSuccess({ data: res })),
    catchError((error) => of(new ClientsBonusByPeriodLoadError(error)))
  );

  @Effect()
  loadOutletBonus: Observable<Action> = this.actions$.pipe(
    ofType<OutletBonusesLoadRequest>(BonusActionsTypes.OutletBonusesLoadRequest),
    switchMap((action) =>
      this.store$.select(outletSelectors.getActive).pipe(
        filter((outlet) => !!outlet),
        mergeMap((outlet) =>
          this.bonusService.getOutletBonus({
            ...action.payload,
            addressId: outlet.id,
          })
        ),
        map((outletBonus) => {
          let bonusSum = 0;
          outletBonus?.forEach((data) => (bonusSum += data.currentBonus));
          return new OutletBonusesLoadSuccess({ data: outletBonus, bonusSum });
        })
      )
    ),
    catchError((error) => of(new OutletBonusesLoadError(error)))
  );

  @Effect()
  loadOutletBonusesPredict: Observable<Action> = this.actions$.pipe(
    ofType<OutletBonusesPredictLoadRequest>(BonusActionsTypes.OutletBonusesPredictLoadRequest),
    switchMap((action) =>
      this.store$.select(outletSelectors.getActive).pipe(
        filter((outlet) => !!outlet),
        mergeMap((outlet) =>
          this.bonusService.getOutletBonusesPredict({
            ...action.payload,
            addressId: outlet.id,
          })
        ),
        mergeMap((outletBonusesPredict) => {
          let bonusPredictSum = 0;
          outletBonusesPredict?.forEach((data) => (bonusPredictSum += data.currentBonus));
          return [
            new OutletBonusesPredictLoadSuccess({
              data: outletBonusesPredict,
              bonusPredictSum,
            }),
          ];
        })
      )
    ),
    catchError((error) => of(new OutletBonusesPredictLoadError(error)))
  );

  @Effect()
  loadOutletBonusPredict: Observable<Action> = this.actions$.pipe(
    ofType<OutletBonusPredictLoadRequest>(BonusActionsTypes.OutletBonusPredictLoadRequest),
    switchMap((action) =>
      this.store$.select(outletSelectors.getActive).pipe(
        filter((outlet) => !!outlet),
        mergeMap((outlet) => this.bonusService.getOutletBonusPredict(outlet.id)),
        map((outletBonusPredict) => new OutletBonusPredictLoadSuccess({ data: outletBonusPredict }))
      )
    ),
    catchError((error) => of(new OutletBonusPredictLoadError(error)))
  );

  @Effect({ dispatch: false })
  setActive: Observable<Action> = this.actions$.pipe(
    ofType<SetActiveBonus>(BonusActionsTypes.SetActiveBonus),
    withLatestFrom(this.store$.select(outletSelectors.getActive)),
    mergeMap(([action, activeOutlet]) => {
      const query: IProductParamPlan = {
        ...this.productTableService.queryParam.value,
        addressId: activeOutlet.id,
        // clientId: activeOutlet.client.id,
        pageNumber: 0,
        pageSize: 8,
        planId: action.payload.bonus ? action.payload.bonus.planId : null,
      };
      const filterParam = Object.entries(query).reduce((a, [k, v]) => (v === null ? a : ((a[k] = v), a)), {}) as IProductParamPlan;
      this.productTableService.setQuery(filterParam);
      return of(null);
    })
  );

  @Effect()
  bonusChangeOutlet: Observable<Action> = this.actions$.pipe(
    ofType<OutletSetActive | OutletSetActiveId>(OutletActionsTypes.OutletSetActive, OutletActionsTypes.OutletSetActiveId),
    map(() => {
      return new ResetBonusValue();
    })
  );

  @Effect()
  loadBonusOnReset: Observable<Action> = this.actions$.pipe(
    ofType<ResetBonusValue>(BonusActionsTypes.ResetBonusValue),
    withLatestFrom(this.store$.select(outletSelectors.getActive)),
    mergeMap(([param, outlet]) => {
      return [
        new OutletBonusesLoadRequest({ addressId: outlet.id }),
        new OutletBonusesPredictLoadRequest({ addressId: outlet.id }),
        new OutletAwaitingSumLoadRequest({ addressId: outlet.id }),
        new ClientBonusLoadRequest(),
        new ClientBonusPredictLoadRequest(),
      ];
    })
  );

  @Effect()
  loadSelectedUserBonusBalance: Observable<Action> = this.actions$.pipe(
    ofType<SelectedUserBonusBalanceLoad>(BonusActionsTypes.SelectedUserBonusBalanceLoad),
    map((action) => action.payload),
    mergeMap((payload) => this.bonusService.getUserBonusBalance(payload.userId)),
    map((data) => new SelectedUserBonusBalanceLoadSuccess(data)),
    catchError((error) => of(new SelectedUserBonusBalanceLoadError(error)))
  );

  // @Effect()
  // LoadPrediction = this.actions$.pipe(
  //     ofType<LoadPrediction>(BonusActionsTypes.LoadPredictionSuccess),
  //     withLatestFrom(this.store$.select(outletSelectors.getActive)),
  //     filter(([, outlet]) => !!outlet),
  //     switchMap(([, outlet]) => {
  //         return this.
  //     })
  // )
}
