import { Action } from '@ngrx/store';
import {
  Balance,
  FinanceFullData,
  FinanceSchedule,
  GetFinanceCriteria,
  PayHistory,
  UserFinanceInformation,
  SumByDistributor,
  BalanceTreeByDistributor,
} from '@Mesh/store/finance/finance.models';
import { PageableList } from '@Mesh/core/models/pageable';
import { GetFinanceSum } from '@Mesh/core/models/request/get-finance-sum';
import { FinanceSum } from '../../core/models/finance-sum';

export enum FinanceActionsTypes {
  LoadPayHistory = '[Finance] Load Pay History',
  LoadPayHistorySuccess = '[Finance] Load Pay History Success',
  LoadPayHistoryError = '[Finance] Load Pay History Error',

  LoadPayHistoryPage = '[Finance] Load Pay History Page',
  LoadPayHistoryPageFailure = '[Finance] Load Pay History Page Error',

  LoadBalance = '[Finance] Load Balance',
  LoadBalanceSuccess = '[Finance] Load Balance Success',
  LoadBalanceError = '[Finance] Load Balance Error',
  ResetHistoryFinanceValue = '[Finance] reset history finance value',

  LoadUserFinanceSum = '[Finance] Load User Finance Sum',
  LoadUserFinanceSumSuccess = '[Finance] Load User Finance Sum Success',
  LoadUserFinanceSumError = '[Finance] Load User Finance Sum Error',
  LoadFinanceFullData = '[Finance] Load Finance Full Data',
  LoadFinanceFullDataSuccess = '[Finance] Load Finance Full Data Success',
  LoadFinanceFullDataError = '[Finance] Load Finance Full Data Error',
  LoadUserFinanceInformation = '[Finance]  Load User Finance Information',
  LoadUserFinanceInformationSuccess = '[Finance] Load USer Finance Information Success',
  LoadUserFinanceInformationError = '[Finance] Load User Finance Information Error',

  LoadBalanceTree = '[Finance] Load Balance Tree',
  LoadBalanceTreeSuccess = '[Finance] Load Balance Tree Success',
  LoadBalanceTreeError = '[Finance] Load Balance Tree Error',
}

export class LoadPayHistory implements Action {
  readonly type = FinanceActionsTypes.LoadPayHistory;

  constructor(public criteria: GetFinanceCriteria) {}
}

export class LoadPayHistoryPage implements Action {
  readonly type = FinanceActionsTypes.LoadPayHistoryPage;

  constructor(public body: { page: number; itemsPerPage: number; startDate: string; finishDate: string }) {}
}

export class LoadPayHistoryPageFailure implements Action {
  readonly type = FinanceActionsTypes.LoadPayHistoryPageFailure;

  constructor(public error?: any) {}
}

export class LoadPayHistorySuccess implements Action {
  readonly type = FinanceActionsTypes.LoadPayHistorySuccess;

  constructor(public data: PageableList<PayHistory>) {}
}

export class LoadPayHistoryError implements Action {
  readonly type = FinanceActionsTypes.LoadPayHistoryError;
}

export class LoadBalance implements Action {
  readonly type = FinanceActionsTypes.LoadBalance;

  constructor(public criteria: GetFinanceCriteria) {}
}

export class LoadBalanceSuccess implements Action {
  readonly type = FinanceActionsTypes.LoadBalanceSuccess;
  constructor(public data: Balance) {}
}

export class LoadBalanceError implements Action {
  readonly type = FinanceActionsTypes.LoadBalanceError;
}

export class ResetHistoryFinanceValue implements Action {
  readonly type = FinanceActionsTypes.ResetHistoryFinanceValue;
}

export class LoadUserFinanceSum implements Action {
  readonly type = FinanceActionsTypes.LoadUserFinanceSum;
  constructor(public payload: GetFinanceSum) {}
}

export class LoadUserFinanceSumSuccess implements Action {
  readonly type = FinanceActionsTypes.LoadUserFinanceSumSuccess;
  constructor(public data: FinanceSum) {}
}

export class LoadUserFinanceSumError implements Action {
  readonly type = FinanceActionsTypes.LoadUserFinanceSumError;
  constructor(public error: { errors: any[] | null }) {}
}

export class LoadFinanceFullData implements Action {
  readonly type = FinanceActionsTypes.LoadFinanceFullData;
  constructor(public body: { page: number; itemsPerPage: number; startDate: string; finishDate: string }) {}
}

export class LoadFinanceFullDataSuccess implements Action {
  readonly type = FinanceActionsTypes.LoadFinanceFullDataSuccess;
  constructor(public data: FinanceFullData[]) {}
}

export class LoadFinanceFullDataError implements Action {
  readonly type = FinanceActionsTypes.LoadFinanceFullDataError;
}

export class LoadUserFinanceInformation implements Action {
  readonly type = FinanceActionsTypes.LoadUserFinanceInformation;
}

export class LoadUserFinanceInformationSuccess implements Action {
  readonly type = FinanceActionsTypes.LoadUserFinanceInformationSuccess;
  constructor(public data: UserFinanceInformation) {}
}

export class LoadUserFinanceInformationError implements Action {
  readonly type = FinanceActionsTypes.LoadUserFinanceInformationError;
}

export class LoadBalanceTree implements Action {
  readonly type = FinanceActionsTypes.LoadBalanceTree;

  constructor(public criteria: any) {}
}

export class LoadBalanceTreeSuccess implements Action {
  readonly type = FinanceActionsTypes.LoadBalanceTreeSuccess;
  constructor(public data: BalanceTreeByDistributor[]) {}
}

export class LoadBalanceTreeError implements Action {
  readonly type = FinanceActionsTypes.LoadBalanceTreeError;

  constructor(public error?: any) {}
}

export type FinanceActionsUnion =
  | LoadPayHistory
  | LoadPayHistorySuccess
  | LoadPayHistoryError
  | LoadBalance
  | LoadBalanceSuccess
  | LoadBalanceError
  | ResetHistoryFinanceValue
  | LoadUserFinanceSum
  | LoadUserFinanceSumSuccess
  | LoadUserFinanceSumError
  | LoadFinanceFullData
  | LoadFinanceFullDataSuccess
  | LoadFinanceFullDataError
  | LoadUserFinanceInformation
  | LoadUserFinanceInformationSuccess
  | LoadUserFinanceInformationError
  | LoadBalanceTree
  | LoadBalanceTreeSuccess
  | LoadBalanceTreeError;
