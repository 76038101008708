import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutletLocationDropdownComponent } from './outlet-location-dropdown.component';

@NgModule({
  declarations: [OutletLocationDropdownComponent],
  exports: [OutletLocationDropdownComponent],
  imports: [CommonModule],
})
export class OutletLocationDropdownModule {}
