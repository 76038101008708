import { Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { BlankComponent, CondensedComponent } from './@pages/layouts';
import { CondensedDashboardComponent } from './pages/dashboard/condensed/dashboard.component';
import { ErrorComponent } from './pages/error/error.component';
import { UnderConstructionComponent } from '@Mesh/pages/under-construction/under-construction.component';
import { RoleGuard } from '@Mesh/core/guards/role.guard';
import { ActiveGuard } from './core/guards/active.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: 'Главная',
      pageClass: 'page-dashboard-wrapper',
    },
    pathMatch: 'full',
    component: CondensedComponent,
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        component: CondensedDashboardComponent,
      },
    ],
  },
  {
    path: 'dashboard',
    component: CondensedComponent,
    data: {
      pageClass: 'page-dashboard-wrapper',
    },
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        component: CondensedDashboardComponent,
      },
    ],
  },
  {
    path: 'catalog',
    data: {
      breadcrumb: 'Каталог',
    },
    component: CondensedComponent,
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        loadChildren: './pages/catalog/catalog.module#CatalogModule',
      },
    ],
  },
  {
    path: 'orders',
    component: CondensedComponent,
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        loadChildren: './pages/orders/orders.module#OrdersModule',
      },
    ],
  },
  {
    path: 'tasks',
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    component: CondensedComponent,
    data: {
      breadcrumb: 'Задачи',
    },
    children: [
      {
        path: '',
        loadChildren: './pages/tasks/tasks.module#TasksModule',
      },
    ],
  },
  {
    path: 'education',
    component: CondensedComponent,
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        loadChildren: './pages/education/education.module#EducationModule',
      },
    ],
  },
  {
    path: 'learn',
    data: {
      breadcrumb: 'Банк знаний',
    },
    component: CondensedComponent,
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        loadChildren: './pages/learn/learn.module#LearnModule',
      },
    ],
  },
  {
    path: 'knowledge',
    component: CondensedComponent,
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        loadChildren: './pages/knowledge/knowledge.module#KnowledgeModule',
      },
    ],
  },
  {
    path: 'shop',
    data: {
      breadcrumb: 'Магазин',
    },
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    component: CondensedComponent,
    children: [
      {
        path: '',
        loadChildren: './pages/awards/awards.module#AwardsModule',
      },
    ],
  },
  {
    path: 'active',
    component: CondensedComponent,
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        loadChildren: './pages/active/active.module#ActiveModule',
      },
    ],
  },
  {
    path: 'retail-tasks',
    data: {
      breadcrumb: 'Задания',
    },
    component: CondensedComponent,
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        loadChildren: './pages/retail-tasks/retail-tasks.module#RetailTasksModule',
      },
    ],
  },
  {
    path: 'profile',
    component: CondensedComponent,
    data: {
      pageClass: 'page-profile-wrapper',
    },
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        loadChildren: './pages/profile/profile.module#ProfileModule',
      },
    ],
  },
  {
    path: 'history',
    component: CondensedComponent,
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        loadChildren: './pages/history-list/history-list.module#HistoryListModule',
      },
    ],
  },
  {
    path: 'news',
    data: {
      breadcrumb: 'Новости',
    },
    component: CondensedComponent,
    canActivate: [AuthGuard, RoleGuard, ActiveGuard],
    children: [
      {
        path: '',
        loadChildren: './pages/news/news.module#NewsModule',
      },
    ],
  },
  {
    path: 'auth',
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren: './pages/auth/auth.module#AuthModule',
      },
    ],
  },
  {
    path: 'under_construction',
    component: BlankComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: UnderConstructionComponent,
      },
    ],
  },
  {
    path: 'error',
    component: BlankComponent,
    children: [
      {
        path: ':type',
        component: ErrorComponent,
      },
    ],
  },
  { path: '**', redirectTo: 'error/404' },
];
