import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { objectToParams } from '../../shared/helpers/object.helpers';
import { Observable } from 'rxjs';
import { API_PLAN_URL } from '../../../environments/environment';
import { CatalogProduct, FilterProductsRequest } from '../models/catalog-products.model';
import { IPageableList } from '../models/pageable';
import { CatalogFiltersRequest, CatalogFiltersResponse } from '../models/catalog-filters.model';
import { CategotyTree } from '../models/categories';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  constructor(private readonly http: HttpClient) {}

  filterProducts(request: FilterProductsRequest): Observable<IPageableList<CatalogProduct>> {
    const params = objectToParams(request);
    return this.http.get<IPageableList<CatalogProduct>>(`${API_PLAN_URL}/v1/catalog`, { params });
  }

  getCatalogFilters(request: CatalogFiltersRequest): Observable<CatalogFiltersResponse> {
    const params = objectToParams(request);
    return this.http.get<CatalogFiltersResponse>(`${API_PLAN_URL}/v1/catalog/filters`, { params });
  }

  getCatalogCategories(addressId: number): Observable<CategotyTree[]> {
    const params = objectToParams({ addressId });
    return this.http.get<CategotyTree[]>(`${API_PLAN_URL}/v1/catalog/categories`, { params });
  }
}
