import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap, filter } from 'rxjs/operators';
import { pagesToggleService } from '../../../../@pages/services/toggler.service';
import { removeEmptyParams } from '../../../../shared/helpers/remove-empty-params';
import { AppState } from '../../../../store/app.state';
import { CartSelectors, CartActions } from '../../../../store/cart';
import { LoadCartCategoryTree, UpdateRecommendedOrderInCart } from '../../../../store/cart/cart.actions';
import {
  MinimalCartRuleGroupedStatus,
  CartRuleGroupedStatus,
  HierarchyProduct,
  CartItemDisplay,
  CartSubmitErrorType,
} from '../../../../store/cart/cart.models';
import { LoadBonusesRecommendedOrders } from '../../../../store/order/order.actions';
import { orderSelectors, selectBonusRecommendedOrderFirst } from '../../../../store/order/order.selectors';
import { BasketFillMinimalAlertComponent } from '../basket-fill-minimal-alert/basket-fill-minimal-alert.component';
import { IChangedCartPosition } from '../basket-interfaces';
import { BasketSumNotEqualAlertComponent } from '../basket-sum-not-equal-alert/basket-sum-not-equal-alert.component';
import { BasketStateType } from '../basket.component';
import { CartChangedModalComponent } from '../cart-changed-modal/cart-changed-modal.component';
import { outletSelectors } from '../../../../store/outlet/outlet.selectors';
import { UploadChangeParam } from '../../../../@pages/components/upload/interface';
import { BasketOrdersHistoryProductModalComponent } from '../basket-orders-history-product-modal/basket-orders-history-product-modal.component';
import { ProductTableService } from '../../../../core/services/product-table.service';
import { Product } from '../../../../core/models/product';
import { CartItem } from '../../../../store/cart/cart-item.model';
import { CatalogProduct } from '../../../../core/models/catalog-products.model';

@Component({
  selector: 'app-basket-new',
  templateUrl: './basket-new.component.html',
  styleUrls: ['./basket-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketNewComponent implements OnInit, OnDestroy {
  @Output() changedBasket = new EventEmitter<any>();
  minimalCartRulesGroupStatus$: Observable<MinimalCartRuleGroupedStatus>;
  CartRuleGroupedStatus = CartRuleGroupedStatus;
  readonly cart$ = this.store.select(CartSelectors.selectProductsForCartDisplay);
  readonly totalCartItems$ = this.store.select(CartSelectors.numberOfCartItems);
  readonly totalAmountOfOrder$ = this.store.select(CartSelectors.totalAmountOfOrder);
  readonly selectCartChangedData$ = this.store.select(CartSelectors.selectCartChangedData);
  readonly totalOrderPrice$ = this.store.select(CartSelectors.selectTotalPriceOfOrder);
  readonly totalOrderDiscountPrice$ = this.store.select(CartSelectors.selectTotalDiscountPriceOfOrder);

  readonly recommendedCart$ = this.store.select(CartSelectors.selectRecommendedCart);
  readonly numberOfRecommendedCart$ = this.store.select(CartSelectors.numberOfRecommendedCart);
  readonly numberOfOtherCart$ = this.store.select(CartSelectors.numberOfOtherCart);
  readonly numberOfRecommendCart$ = this.store.select(CartSelectors.numberOfRecommendCart);
  readonly bonusRecommendedOrder$ = this.store.select(selectBonusRecommendedOrderFirst);
  readonly categoryTree$ = this.store.select(CartSelectors.selectCategoryCart);
  readonly submitCartError$ = this.store.select(CartSelectors.selectSubmitCartError);
  readonly outlet$ = this.store.select(outletSelectors.getActive);
  readonly nextDeliveryDate$ = this.store.select(CartSelectors.nextDeliveryDate);

  readonly showOrderDetails$ = this.store.select(CartSelectors.showOrderDetails);
  readonly orderDetails$ = this.store.select(CartSelectors.selectOrderCompletedState);
  readonly currentRecommendedOrderId$ = this.store.select(CartSelectors.selectCurrentRecommendedOrderId);
  readonly currentRecommendedOrderActive$ = this.store.select(CartSelectors.selectCurrentRecommendedOrderActive);
  readonly totalRecommendedCartAmount$ = this.store.select(CartSelectors.totalRecommendedCartAmount);

  stateCart: string = BasketStateType.DEFAULT;
  recommendedOrderId: number;
  modalRef: BsModalRef;
  cartRulesGroupStatus: MinimalCartRuleGroupedStatus;
  cartByDistributors$ = this.store.select(CartSelectors.selectProductsForCartDisplay);
  filtersReset: number;

  protected unsubscribe$ = new Subject();
  uploadFile: UploadChangeParam;
  recommendedOrderActive: boolean;

  constructor(
    private toggler: pagesToggleService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private modalService: BsModalService,
    private productTableService: ProductTableService
  ) {}

  ngOnInit() {
    this.store.dispatch(new CartActions.LoadCart());
    this.store.dispatch(new CartActions.LoadDeliveryDate());
    this.store.dispatch(new LoadBonusesRecommendedOrders({ executed: false, predict: true }));
    this.minimalCartRulesGroupStatus$ = this.store.pipe(
      select(CartSelectors.selectMinimalCartRulesGroupStatus),
      tap((status) => (this.cartRulesGroupStatus = status))
    );
    this.store.pipe(select(CartSelectors.selectCart), takeUntil(this.unsubscribe$)).subscribe((cartList) => {
      this.loadDataAfteChangeCart(cartList);
    });
    this.handleParams();
    this.toggler.basketToggle.subscribe((open) => {
      if (!open) {
        this.uploadFile = null;
        this.store.dispatch(new CartActions.ClearOnOrderCompletedState());
      }
    });
    this.initErrorSubmit();
    this.selectCartChangedData$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.showChangedCardModal(data.error);
      }
    });

    this.store.pipe(select(CartSelectors.selectCurrentRecommendedOrderActive), takeUntil(this.unsubscribe$)).subscribe((active) => {
      this.recommendedOrderActive = active;
      this.cdr.markForCheck();
    });
  }

  onChangeBasket(): void {
    this.changedBasket.emit();
  }

  showChangedCardModal(data: { positions: IChangedCartPosition[]; message: string }): void {
    this.modalRef = this.modalService.show(CartChangedModalComponent, {
      initialState: { data },
      class: 'cart-changed-modal',
      animated: false,
    });
  }

  onFilterChanges(params: Partial<HierarchyProduct>): void {
    removeEmptyParams(params);
    const keysParams = Object.keys(params);

    this.cartByDistributors$ = this.store.pipe(
      select(CartSelectors.selectProductsForCartDisplay),
      map((carts) =>
        carts.filter((item) => {
          return keysParams.every((key) => params[key].includes(item.hierarchy[key]));
        })
      )
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private handleParams(): void {
    this.route.queryParamMap.pipe(takeUntil(this.unsubscribe$)).subscribe((paramsMap) => {
      this.initTypesBasket(paramsMap);
    });
  }

  order() {
    this.store.dispatch(new CartActions.SubmitCart());
  }

  incrementAmount(product: CartItemDisplay) {
    this.store.dispatch(
      new CartActions.IncrementProductInCart({
        id: product.id,
        unit: product.unit,
        materialId: product.materialId,
        distributorName: product.distributorName,
      })
    );
  }

  decrementAmount(product: CartItemDisplay) {
    this.store.dispatch(
      new CartActions.DecrementProductInCart({
        id: product.id,
        unit: product.unit,
        materialId: product.materialId,
        distributorName: product.distributorName,
      })
    );
  }

  changeAmount({ product, amount }): void {
    this.store.dispatch(
      new CartActions.ChangeAmount({
        product,
        amount,
      })
    );
  }

  deleteCartItem(product: CartItemDisplay, allList?: CartItemDisplay[]) {
    this.store.dispatch(new CartActions.DeleteProduct(product));
    if (allList && allList.length === 1) {
      this.filtersReset = new Date().getTime();
    }
  }

  onToggleFavorite(product: CartItemDisplay): void {
    this.store.dispatch(new CartActions.ToggleProductFavorite({ materialId: product.materialId }));
    this.productTableService.setFavoriteProduct(product as unknown as CatalogProduct);
  }

  onClosedRecommendedOrder() {
    this.store.dispatch(new CartActions.ResetCurrentRecommendedOrderId());
    this.store.dispatch(new CartActions.ResetRecommendedOrderInCart());
    this.store.dispatch(new CartActions.LoadCart());
    this.stateCart = BasketStateType.DEFAULT;
  }

  closeCart() {
    this.toggler.toggleBacket(false);
  }

  private initTypesBasket(params: ParamMap): void {
    if (params.has('action') && params.get('action') === 'basket') {
      this.stateCart = BasketStateType.DEFAULT;
      if (params.has('action_type')) {
        switch (params.get('action_type')) {
          case 'task': {
            this.stateCart = BasketStateType.TASK;
            this.cdr.markForCheck();
            if (params.has('action_id')) {
              this.recommendedOrderId = +params.get('action_id');
              this.store.dispatch(new CartActions.SetCurrentRecommendedOrderId({ recommendedOrderId: this.recommendedOrderId }));

              this.store.dispatch(
                new LoadBonusesRecommendedOrders({
                  recommendedOrderId: this.recommendedOrderId,
                  executed: false,
                  predict: true,
                })
              );
              this.onLoadCartListOrder(this.recommendedOrderId);
              this.cdr.markForCheck();
            }
            break;
          }

          case 'catalog': {
            this.stateCart = BasketStateType.CATALOG;
            this.cdr.markForCheck();
            break;
          }

          default:
            this.stateCart = BasketStateType.DEFAULT;
            this.cdr.markForCheck();
            break;
        }
      }
    }
  }

  onLoadCartListOrder(recommendedOrderId: number): void {
    this.recommendedOrderId = recommendedOrderId;
    this.store.dispatch(new CartActions.SetCurrentRecommendedOrderId({ recommendedOrderId }));

    this.store.dispatch(new CartActions.ResetRecommendedOrderInCart());
    if (recommendedOrderId) {
      this.store.dispatch(new CartActions.LoadRecommendedOrderInCart({ recommendedOrderId }));
    }
  }

  onActiveChangeRecommendedOrder({ active, recommendedOrderId }): void {
    if (active) {
      this.onLoadCartListOrder(recommendedOrderId);
    } else {
      this.onClosedRecommendedOrder();
    }
    this.cdr.markForCheck();
  }

  onUpdateBasketToOrder(recommendedOrderId: number): void {
    this.store.dispatch(new CartActions.ResetRecommendedOrderInCart());
    this.store.dispatch(new CartActions.LoadRecommendedOrderInCart({ recommendedOrderId }));
  }

  onShowFillMinimalModal(): void {
    this.modalRef = this.modalService.show(BasketFillMinimalAlertComponent, {
      class: 'modal-dialog-centered',
      initialState: { minimalCartRuleCart: this.cartRulesGroupStatus.minimalCartRuleCartResponses },
    });
    this.modalRef.content.canceled.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.onClosedModal();
    });
    this.modalRef.content.ordered.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.order();
      this.onClosedModal();
    });
    this.modalRef.content.minimalAdded.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.minimalAddedToCart();
    });
  }

  onShowOrderHistory(product: CartItemDisplay): void {
    this.modalRef = this.modalService.show(BasketOrdersHistoryProductModalComponent, {
      class: 'modal-dialog-centered modal-notify-small',
      initialState: { orderHistoryList: product.ordersHistory },
      animated: false,
    });
    this.modalRef.content.closed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.onClosedModal();
    });
  }

  onClosedModal() {
    this.modalRef?.hide();
  }

  minimalAddedToCart() {
    this.store.dispatch(new CartActions.FillUpCartsMinimalLimit());
    this.onClosedModal();
  }

  onOrder() {
    if (
      this.cartRulesGroupStatus &&
      (this.cartRulesGroupStatus.status === CartRuleGroupedStatus.NotAvailable ||
        this.cartRulesGroupStatus.status === CartRuleGroupedStatus.PartialAvailable)
    ) {
      this.onShowFillMinimalModal();
    } else {
      this.order();
    }
  }

  onUploadFile(event: UploadChangeParam): void {
    if (event.file.status === 'error') {
      console.log('Ошибка загрузки файла');
    }
    this.uploadFile = event;
  }

  onUploadFileDone(): void {
    this.uploadFile = null;
  }

  private initErrorSubmit(): void {
    this.submitCartError$.pipe(takeUntil(this.unsubscribe$)).subscribe((err) => {
      console.log(err);
      if (err && err.status === 400) {
        this.onShowErrorModal(err.error.type);
      }
    });
  }

  private onShowErrorModal(type: string): void {
    if (type === CartSubmitErrorType.CART_SUM_NOT_EQUAL_ERROR) {
      this.modalRef = this.modalService.show(BasketSumNotEqualAlertComponent, {
        class: 'modal-dialog-centered modal-notify basket-error',
      });
      this.modalRef.content.updatedCart.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        this.store.dispatch(new CartActions.ResetCart());
        this.store.dispatch(new CartActions.LoadCart());
        this.onClosedModal();
      });
    }
  }

  trackById(index: number, item: CartItemDisplay): number {
    return item.materialId;
  }

  private loadDataAfteChangeCart(cartList: CartItem[]) {
    if (cartList && cartList.length) {
      this.store.dispatch(new CartActions.LoadMinimalCartRuleGroupedStatus());
      this.store.dispatch(new LoadCartCategoryTree());
    } else {
      this.store.dispatch(new CartActions.ResetMinimalCartRuleGroupedStatus());
      this.store.dispatch(new CartActions.ResetCartCategoryTree());
    }
  }
}
