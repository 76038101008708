import { Product, SalePlans } from '../../core/models/product';
import { OrderHistoryItem } from '../order/order.model';

export interface HierarchyProduct {
  materialGroupNameId: string;
  categoryNameId: string;
  manufacturerNameId: string;
  brandNameId: string;
  productTypeNameId: string;
  brandFamilyNameId: string;
}

export interface CartItem {
  id: number;
  materialId: number;
  quantity: number;
  mrc: number;
  unit: string;
  price: number;
  ndsPrice: number;
  discountPrice: number;
  discountNdsPrice: number;
  userId: number;
  type: CartItemType;
  salesOrgSapId: string;
  distributorCode: string;
  distributorName: string;
  hierarchy: HierarchyProduct;
  deliveryDate: string;
}

export interface UpdateCartItem {
  recommendedOrderId?: number;
  catalogDistributorId: number;
  addressId: number;
  clientId: number;
  userId: number;
  productId: number;
  measurementUnitsCoefId: number;
  quantity: number;
  stockId: number;
  priceListId: number;
}

export interface UpdateCartItemPayload {
  catalogDistributorId: number;
  addressId: number;
  clientId: number;
  userId: number;
  productId: number;
  measurementUnitsCoefId: number;
  quantity: number;
  stockId: number;
  priceListId: number;
}

export interface DeleteCartItem {
  materialId: number;
  unit: string;
  mrc?: number;
  salesOrgSapId?: string;
  distributorCode?: number;
}

export interface DeleteCartItemCriteria {
  addressId: number;
  userId: number;
  materialId: number;
  unit: string;
  mrc?: number;
  salesOrgSapId?: string;
  distributorCode?: number;
}

export interface MinimalCartRulesCriteria {
  addressId?: number;
  materialGroupNameId?: string;
  userId?: number;
  recommendedOrderId?: number;
  isRecommendedWithOthers?: boolean;
  type?: string; // NORMAL, RECOMMENDED, DELAYED
}

export interface LoadCartCriteria {
  addressId?: number;
  userId?: number;
  recommendedOrderId?: number;
}

export interface MinimalCartRules {
  id: number;
  amount: number;
  name: string;
  currentAmount: number;
  cartsIds: number[];
  categoryNames: any[];
}

export interface MinimalCartRuleGroupedStatus {
  status: CartRuleGroupedStatus;
  minimalCartRuleCartResponses: MinimalCartRules[];
}

export enum CartRuleGroupedStatus {
  NotAvailable = 'NOT_AVAILABLE',
  FullAvailable = 'FULL_AVAILABLE',
  PartialAvailable = 'PARTIAL_AVAILABLE',
}

export enum OrderCompletedStatusType {
  ORDER_NORMAL = 'NORMAL',
  ORDER_DELAYED = 'DELAYED',
}

export enum CartItemType {
  NORMAL = 'NORMAL',
  RECOMMENDED = 'RECOMMENDED',
}

export enum CartSubmitErrorType {
  CART_SUM_NOT_EQUAL_ERROR = 'CART_SUM_NOT_EQUAL_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}
export interface CartItemDisplay {
  materialId: number;
  image: string;
  name: string;
  mrc: number;
  pricePerUnit: number;
  unit: string;
  baseQuantUnit: number;
  baseUnit: string;
  baseNdsPrice: number;
  ndsPrice: number;
  price: number;
  discountPrice: number;
  discountNdsPrice: number;
  totalNdsPriceTag: number;
  totalDiscountNdsPriceTag: number;
  hasDiscount: boolean;
  quantity: number;
  orderAmount: number;
  id: number; // mean productId
  quantityMaterial: number;
  distributorCode?: number;
  distributorName?: string;
  type: string;
  hierarchy: HierarchyProduct;
  deliveryDate: string;
  ordersHistory?: Array<OrderHistoryItem>;
  isFavorite: boolean;
  salePlans: SalePlans[];
  planIds?: number[];
}

export interface OrderCompletedState {
  orderNumber?: string;
  delayedOrderId?: number;
  items: OrderCompletedItem[];
  delayedTo: string;
  type: string;
  closestDate?: string;
  deliveryDate?: string;
  totalPrice: number;
  totalAmount: number;
  totalDiscountPrice: number;
  distributorName?: string;
  distributorCode?: string;
  salesOrgSapId?: string;
}

export interface OrderCompletedItem {
  name: string;
  amount: number;
  unit: string;
  distributorName: string;
  price: number;
  image: string;
}

export interface OrderCompleted {
  orderNumber?: any;
  delayedOrderId?: number;
  orderSum: number;
  type: string;
  delayedTo: Date;
  carts: CartItem[];
  distributorName?: any;
  deliveryDate?: any;
}

export interface CartCategoryTreeCriteria {
  addressId: number;
  recommendedOrderId?: number;
  delayedOrderId?: number;
  userId?: number;
  direction?: string;
}
export interface CartFileAddressProcess {
  id: number;
  filePath: string;
  userId: number;
  createdAt: string;
  updatedAt: string;
  cartFileAddressResponseList: CartFileAddressResponseList[];
}

export interface CartFileAddressResponseList {
  id: number;
  cartFileId: number;
  clientId: number;
  addressId: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  cartFilePositionResponseList: CartFilePositionResponseList[];
}

export interface CartFilePositionResponseList {
  id: number;
  cartFileAddressId: number;
  materialId: number;
  loadedQuantity: number;
  calculatedQuantity: number;
  mrc: number;
  product: Product;
}

export enum CartFileAddressStatusType {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
}
