import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, share } from 'rxjs/operators';
import { Product } from '../models/product';
import { CatalogProduct } from '../models/catalog-products.model';

export interface IProductParam {
  addressId: number;
  pageNumber: number;
  pageSize: number;
  sort: string[];
}

export interface IProductParamCategory extends IProductParam {
  materialName?: string;
  manufacturerNameId?: string;
  categoryNameId?: string;
  brandNameId?: string;
  brandFamilyNameId?: string;
  productTypeNameId?: string;
  materialGroupNameId?: string;
  planId?: number;
  onlyFavorites?: boolean;
}

export interface IProductParamPlan extends IProductParam {
  planId: number;
}

class ProductParam implements IProductParam {
  addressId: number = null;
  clientId: number = null;
  pageNumber: number = 0;
  pageSize: number = 12;
  sort = ['name|ASC'];

  static empty() {
    return new ProductParam();
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductTableService {
  readonly changeProductFavorite$ = new BehaviorSubject<CatalogProduct>(null);
  readonly queryParam = new BehaviorSubject<IProductParam>(ProductParam.empty());
  private readonly queryParam$ = this.queryParam.asObservable();

  readonly queryParamChanged$ = this.queryParam$.pipe(
    distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
    debounceTime(300)
  );

  setQuery(query: IProductParam) {
    this.queryParam.next(query);
  }

  setFavoriteProduct(product: CatalogProduct): void {
    this.changeProductFavorite$.next(product);
  }

  onChangeFavoriteProduct(): Observable<CatalogProduct> {
    return this.changeProductFavorite$.pipe(share());
  }
}
