import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cartFeatureKey, CartState } from '@Mesh/store/cart/cart.reducers';
import { CartItemDisplay, CartItemType } from '@Mesh/store/cart/cart.models';
import { IMAGES_URL } from '@Env/environment';
import { RequestStatus } from '@Mesh/shared/enums/request-status.enum';
import { CartItem } from './cart-item.model';

const selectCartState = createFeatureSelector<CartState>(cartFeatureKey);

export const selectCart = createSelector(selectCartState, (state) => state.cart);

export const numberOfCartItems = createSelector(selectCart, (items) => items.length);

export const getCartAmount = createSelector(selectCart, (items) => items.reduce((prev, curr) => prev + (curr?.quantity || 0), 0));

export const selectTotalCartPrice = createSelector(selectCart, (items) => {
  return items.reduce((prev, curr) => prev + (curr.price || 0), 0);
});

const selectCartProducts = createSelector(selectCartState, (state) => state.cartProducts);

export const selectMinimalCartRules = createSelector(selectCartState, (state) => state.minimalCartRules);

export const selectMinimalCartRulesGroupStatus = createSelector(selectCartState, (state) => state.minimalCartRulesGroupStatus);

export const selectCategoryCart = createSelector(selectCartState, (state) => state.categoryCart);

const selectCartProductById = (productId: number) => {
  return createSelector(selectCartProducts, (products) => {
    return products.find((el) => el.id === productId);
  });
};

export const selectCartItemByMaterialIdAndUnit = (materialId: number, unit: string) => {
  return createSelector(selectCart, (items) => {
    return items.find((el) => el.material.id === materialId && el.unit === unit);
  });
};

export const selectCartItemByMaterialIdAndUnitAndDistributorName = (
  materialId: number,
  measurementUnitsCoefId: number,
  catalogDistributorId: number
) => {
  return createSelector(selectCart, (items) => {
    return items.find(
      (cartItem) => cartItem.material.id === materialId
      // cartItem.stockProductUnit.measurementUnitsCoef.id === measurementUnitsCoefId &&
      // cartItem.stockProductUnit.stock.catalogDistributor.id === catalogDistributorId
    );
  });
};
export const selectOrdersHistoryMap = createSelector(selectCartState, (state) => state.ordersHistoryMap);

export const selectProductsForCartDisplay = createSelector(
  selectCart,
  selectCartProducts,
  selectOrdersHistoryMap,
  (cartItems, cartProducts, ordersHistoryMap) => {
    return cartItems.map((item: CartItem): CartItemDisplay => {
      const result: Partial<CartItemDisplay> = {
        materialId: item.material.id,
        unit: item.unit,
        mrc: item.mrc,
        ndsPrice: item.ndsPrice,
        price: item.price,
        discountNdsPrice: item.discountNdsPrice,
        discountPrice: item.discountPrice,
        totalNdsPriceTag: item.quantity * item.ndsPrice,
        totalDiscountNdsPriceTag: item.quantity * item.discountNdsPrice,
        quantity: item.quantity,
        hasDiscount: item.ndsPrice !== item.discountNdsPrice,
        // salesOrgSapId: item.salesOrgSapId,
        distributorCode: item.stockProductUnit.stock.catalogDistributor.id,
        distributorName: item.stockProductUnit.stock.catalogDistributor.distritorName,
        deliveryDate: null,
        type: item.type,
        hierarchy: null,
        ordersHistory: ordersHistoryMap[item.material.id],
      };
      const product = cartProducts.find((el) => el.id === item.material.id);
      if (product) {
        const stock = item.stockProductUnit.stock;
        result.name = product.name;
        // result.baseQuantUnit = stock?.baseQuantUnit;
        // result.baseUnit = stock?.baseUnit;
        // result.baseNdsPrice = stock?.baseNdsPrice;
        result.orderAmount = item.quantity;
        result.image = IMAGES_URL + product.image;
        result.id = product.id;
        // result.quantityMaterial = stock;
        result.isFavorite = false;
        result.salePlans = product.salePlanResponses || [];
        result.planIds = (product.salePlanResponses || []).map((plan) => plan.planId);
      }
      return result as CartItemDisplay;
    });
  }
);

export const totalAmountOfOrder = createSelector(selectProductsForCartDisplay, (items) => {
  return items.reduce((prev, curr) => prev + curr.orderAmount, 0);
});

export const selectTotalPriceOfOrder = createSelector(selectProductsForCartDisplay, (items) => {
  return items.reduce((rev, curr) => rev + curr.totalNdsPriceTag, 0);
});

export const selectTotalDiscountPriceOfOrder = createSelector(selectProductsForCartDisplay, (items) => {
  return items.reduce((rev, curr) => rev + curr.totalDiscountNdsPriceTag, 0);
});

export const selectCartItemByProductIdInRows = (productId: number) => {
  return createSelector(
    selectDistributorDataByProductId(productId),
    selectMeasurementUnitsCoefByProductId(productId),
    selectCart,
    (catalogDistributor, measurementUnitsCoef, items) => {
      console.log(catalogDistributor, measurementUnitsCoef, items);
      return items.find(
        (cartItem) => cartItem.material.id === productId
        // cartItem.stockProductUnit.measurementUnitsCoef.id === measurementUnitsCoef.id &&
        // cartItem.stockProductUnit.stock.catalogDistributor.id === catalogDistributor.id
      );
    }
  );
};

export const selectCartItemQuantityByProductIdInRows = (productId: number) => {
  return createSelector(selectCartItemByProductIdInRows(productId), (cartItem) => {
    console.log('quantity :', cartItem?.quantity);
    return cartItem?.quantity || 0;
  });
};

export const possibleToDecrement = (productId: number) => {
  return createSelector(selectCartItemQuantityByProductIdInRows(productId), (quantity) => {
    return quantity > 0;
  });
};

export const productIsFavorite = (productId: number) => {
  return createSelector(selectCartProductById(productId), (cartItem) => {
    return cartItem.isFavorite;
  });
};

export const possibleToIncrement = (productId: number) => {
  return createSelector(
    selectDistributorDataByProductId(productId),
    selectCartItemQuantityByProductIdInRows(productId),
    (stock, quantity) => {
      return stock?.stockResponse.stockLeftoverResponse.quantity > quantity;
    }
  );
};

export const selectMaxCountSelectedStock = (productId: number) => {
  return createSelector(selectDistributorDataByProductId(productId), (stock) => {
    return stock?.stockResponse.stockLeftoverResponse.quantity || 0;
  });
};

export const selectOrderCompletedState = createSelector(selectCartState, (state) => {
  return state.orderCompletedState;
});

export const selectCartChangedData = createSelector(selectCartState, (state) => {
  return state.cartChangedData;
});

export const showOrderDetails = createSelector(selectCartState, (state) => {
  return state.submitCartStatus === RequestStatus.SUCCESS;
});

// export const groupByMaterialGroupNameId = createSelector(selectCartProducts, (products) => {
//   return (products || []).reduce(
//     (entryMap, product) => entryMap.set(product.materialGroupNameId, [...(entryMap.get(product.materialGroupNameId) || []), product]),
//     new Map()
//   );
// });

export const selectRecommendedCart = createSelector(selectCartState, (state) => state.recommendedCart);
export const selectDefaultCart = createSelector(selectCartState, (state) => state.defaultCart);
export const totalRecommendedCartAmount = createSelector(selectRecommendedCart, (items) => {
  return items.reduce((prev, curr) => prev + curr.ndsPrice * curr.quantity, 0);
});

export const numberOfRecommendedCart = createSelector(selectRecommendedCart, (items) => items.length);
export const numberOfOtherCart = createSelector(
  selectCart,
  (items) => items.filter((item) => item.type.indexOf(CartItemType.NORMAL) !== -1).length
);
export const numberOfRecommendCart = createSelector(
  selectCart,
  (items) => items.filter((item) => item.type.indexOf(CartItemType.RECOMMENDED) !== -1).length
);
export const nextDeliveryDate = createSelector(selectCartState, (state) => state.nextDeliveryDate);
export const changesRecommendedCart = createSelector(selectCartState, (state) => state.changesRecommendedCart);
export const selectSubmitCartError = createSelector(selectCartState, (state) => state.submitCartError);
export const selectFillCartByFileProgress = createSelector(selectCartState, (state) => state.fillByFileProgress);
export const selectCurrentRecommendedOrderId = createSelector(selectCartState, (state) => state.currentRecommendedOrderId);
export const selectCurrentRecommendedOrderActive = createSelector(selectCartState, (state) => Boolean(state.currentRecommendedOrderId));
export const selectTaskRecommendedOrder = createSelector(selectCartState, (state) => state.taskRecommendedOrder);
export const selectShowRecommendedOrderButton = createSelector(selectCartState, (state: CartState) => state.showRecommendedOrderButton);
export const selectDistributorStockMap = createSelector(selectCartState, (state: CartState) => state.distributorStockMap);
export const selectMeasurementUnitsCoefMap = createSelector(selectCartState, (state: CartState) => state.measurementUnitsCoefMap);

export const selectDistributorDataByProductId = (productId: number) =>
  createSelector(selectDistributorStockMap, (distributorStockMap) => distributorStockMap[productId] || null);

export const selectMeasurementUnitsCoefByProductId = (productId: number) =>
  createSelector(selectMeasurementUnitsCoefMap, (measurementUnitsCoefMap) =>
    measurementUnitsCoefMap ? measurementUnitsCoefMap[productId] : null
  );
