import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { Observable } from 'rxjs';
import { OutletState } from '../../../store/outlet/outlet.state';
import { outletSelectors } from '../../../store/outlet/outlet.selectors';
import { map } from 'rxjs/operators';
import { Outlet } from '../../../core/models';
import { OutletLoadRequest, OutletSetActive } from '../../../store/outlet/outlet.actions';

@Component({
  selector: 'app-outlet-location-dropdown',
  templateUrl: './outlet-location-dropdown.component.html',
  styleUrls: ['./outlet-location-dropdown.component.scss'],
})
export class OutletLocationDropdownComponent implements OnInit {
  @Input() whiteColor: boolean = false;

  isDropdownOpen = false;
  selectedItem: Outlet | null = null;
  itemsOutlets$: Observable<Outlet[]> = this.store.pipe(
    select(outletSelectors.selectOutlet),
    map((data) => data?.data || [])
  );
  activeOutlet$ = this.store.select(outletSelectors.getActive);

  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  constructor(private store: Store<AppState>) {}

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectItem(item: Outlet): void {
    this.isDropdownOpen = false;
    this.onClose.emit(false);
    this.store.dispatch(new OutletSetActive({ active: item }));
  }

  ngOnInit(): void {
    this.store.dispatch(new OutletLoadRequest({}));
  }
}
