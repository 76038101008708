import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserBonusModalComponent } from './user-bonus-modal.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HistoryModule } from '../../../../shared/modules/history/history.module';

@NgModule({
  declarations: [UserBonusModalComponent],
  exports: [UserBonusModalComponent],
  entryComponents: [UserBonusModalComponent],
  imports: [CommonModule, HistoryModule, InfiniteScrollModule],
})
export class UserBonusModalModule {}
